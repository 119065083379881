:root {
  --purple-text: #6e55d3;

  --primary-25: #fcfaff;
  --primary-50: #f9f5ff;
  --primary-100: #f4ebff;
  --primary-200: #e9d7fe;
  --primary-300: #d6bbfb;
  --primary-400: #b692f6;
  --primary-500: #9e77ed;
  --primary-600: #7f56d9;
  --primary-700: #6941c6;
  --primary-800: #53389e;
  --primary-900: #42307d;

  --gray-25: #fcfcfd;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #e4e7ec;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;

  --bluegray-25: #fcfcfd;
  --bluegray-50: #f8f9fc;
  --bluegray-100: #eaecf5;
  --bluegray-200: #c8cce5;
  --bluegray-300: #9ea5d1;
  --bluegray-400: #717bbc;
  --bluegray-500: #4e5ba6;
  --bluegray-600: #3e4784;
  --bluegray-700: #363f72;
  --bluegray-800: #293056;
  --bluegray-900: #101323;

  --purple-25: #fafaff;
  --purple-50: #f4f3ff;
  --purple-100: #ebe9fe;
  --purple-200: #d9d6fe;
  --purple-300: #bdb4fe;
  --purple-400: #9b8afb;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;
  --purple-800: #4a1fb8;
  --purple-900: #3e1c96;

  --text-black: #282828;
  --link-color: #0000ee;
  --black-text: #282828;
  --warning-100: #fef0c7;
  --warning-800: #93370d;
}

$breakpoint-651-above: 651px;
$breakpoint-992-above: 992px;
$breakpoint-991-below: 991px;
$breakpoint-767-below: 767px;
$breakpoint-1200-below: 1200px;

$primary-font-family: "Raleway-medium", sans-serif;
$primary-white-color: #ffffff;
$primary-grey-color: #5c5c6e;
$secondary-grey-color: #5c5c6e80;
$tertiary-grey-color: #93939e;
$primary-black-color: #000000;
$primary-purple-color: #8c7bd1;

$primary-grey-gradient-color: #fefeff;
$secondary-grey-gradient-color: #b3e2e2e2;
$input-border-color: #5c5c6e80;

$primary-font-size: 0.875rem;
$primary-mobile-font-size: 14px;
$heading-font-size: 40px;
$heading-mobile-font-size: 20px;
$heading2-font-size: 24px;
$heading2-mobile-font-size: 20px;
