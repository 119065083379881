@import "@/styles/mixins";

.requestCallback {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #282828;
  text-align: center;
  font-family: var(--man);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  @include desktop {
    font-size: 16px;
  }
  .link {
    color: #282828;
    text-decoration: underline;
  }
  .icon {
    height: 16px;
    width: 16px;
  }
}
