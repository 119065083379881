@import "../../../styles/var";
.brand-logo-wrapper {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  padding-left: 30px;
  img.logo {
    width: 100px;
    height: auto;
  }
}
.failure-wrapper {
  text-align: center;
  inner-wrapper {
    min-height: calc(100vh - 120px);
  }
  .failure-text,
  .help-text {
    font-size: 20px;
    a {
      color: $primary-purple-color;
      text-decoration: none;
      font-size: 20px;
      &:active {
        text-decoration: none;
      }
    }
  }
  .payment-title {
    font-family: "Raleway-bold", sans-serif;
    font-size: 30px;
  }
  .failure-text {
    max-width: 75%;
  }

  .graphic-wrapper {
    margin: 0 auto;
    svg {
      width: 300px;
    }
  }
  .img-store {
    width: 240px;
    &.app-store {
      margin-left: 45px;
    }
  }
}
@media (max-width: $breakpoint-991-below) {
  .failure-wrapper {
    .store-icon-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-store {
        width: 192px;
        &.app-store {
          margin-left: 0;
          margin-top: 28px;
        }
      }
    }
  }
}
