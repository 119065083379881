@import "@/styles/mixins";

.yogaTrialSelectDate {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  .label {
    color: #101828;
    font-family: var(--man-700);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @include desktop {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
  .dateList {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
    @include hideScrollbar;
    .dateChip {
      cursor: pointer;
      height: 40px;
      width: 40px;
      border-radius: 6px;
      @include flex-center;
      flex-direction: column;
      gap: 2px;
      border: 1px solid #e4e7ec;
      flex-shrink: 0;
      @include desktop {
        height: 56px;
        width: 56px;
        border-radius: 8px;
      }

      .date {
        color: #667085;
        font-family: var(--man-600);
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        letter-spacing: 0em;
        @include desktop {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .month {
        color: #667085;
        font-family: var(--man-700);
        font-size: 8px;
        line-height: 11px;
        font-weight: 700;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        @include desktop {
          font-size: 10px;
          line-height: 14px;
        }
      }
      &.active {
        border: 1.5px solid #101828;
        .date {
          color: #101828;
        }
        .month {
          color: #101828;
        }
      }
    }
  }
}
