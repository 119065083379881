@import "../../../styles/mixins";

.hero-section-wrapper {
  background: linear-gradient(0deg, #f4f8fe 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  .hero-container {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    mix-blend-mode: normal;
    align-items: center;
    @include desktop {
      padding-top: 60px;
    }
    .title-and-card-section {
      display: flex;
      flex-direction: column;
      @media (min-width: 360px) {
        width: 100%;
      }
      .heading-section {
        .title {
          font-family: var(--satoshi);
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 54px;
          text-align: center;
          letter-spacing: -0.035em;
          margin: 0px;
          background: linear-gradient(
            276.41deg,
            #406bee 2.09%,
            #406bee 51.92%,
            #f33f5f 123.16%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          @media (min-width: 360px) {
            font-size: 30px;
            line-height: 40px;
          }
          @include desktop {
            padding-left: 56px;
          }
        }
      }
      .card-wrapper {
        width: 100vw;
        margin-left: -20px;
        overflow: hidden;
        @include desktop {
          width: unset;
          margin-left: unset;
          margin-top: -50px;
          overflow: unset;
        }
      }
      .card-section {
        top: 0px;
        position: relative;
        padding-top: 80%;
        width: calc(100% + 90px);
        left: -45px;
        .card-bg-image {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .sub-title-and-cta-section {
      margin-top: -20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .description-section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .description-title {
          font-family: var(--dm-sans);
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 114.7%;
          text-align: center;
          letter-spacing: -0.02em;
          margin: 0px;
          color: var(--bluegray-800);
          @include mobile-lg {
            font-size: 24px;
          }
        }
        .location-text {
          font-family: var(--dm-sans);
          font-weight: 700;
          font-size: 13px;
          line-height: 21px;
          margin: 0;
          text-align: center;
          letter-spacing: 0.11em;
          width: 100%;
          color: #8c7bd1;
        }
        .description {
          color: var(--black-text);
          font-family: var(--dm-sans);
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 162.7%;
          text-align: center;
          margin: 0px;

          &.year-text {
            font-weight: 700;
          }
        }
      }
      .container {
        padding: 0;
      }
    }
    .benefits-image-container {
      width: 100%;
    }
    .benefits-image-wrapper {
      position: relative;
      width: 100%;
      padding-top: 68.57%;
      @include desktop {
        min-width: 536px;
        padding-top: 77.23%;
      }
    }

    @media screen and (min-width: 651px) {
      padding-top: 32px;
      padding-bottom: 67px;
      gap: 16px;
      .title-and-card-section {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .heading-section {
          display: flex;
          flex-direction: column;
          gap: 13px;
          width: 100%;
          .title {
            font-size: 60px;
            line-height: 81px;
            letter-spacing: -0.01em;
            text-align: left;
            background: linear-gradient(
              94.35deg,
              #406bee -2.93%,
              #406bee 40.93%,
              #f33f5f 130.94%
            );
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .card-section {
          left: 0px;
          top: 60px;
          width: 620px;
          padding-top: 64.84%;
        }
      }

      .sub-title-and-cta-section {
        margin-top: 0;
        .description-section {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-left: 0px;
          padding-right: 0px;
          align-items: flex-start;
          margin-top: 32px;
          .description-title {
            font-size: 24px;
            line-height: 24px;
            font-size: 40px;
            line-height: 52px;
            max-width: 842px;
          }
          .description {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26.03px;
            text-align: left;

            &:nth-child(3) {
              margin-top: 8px;
            }
          }
        }
        .container {
          padding: 0;
        }
      }
    }
  }
}
