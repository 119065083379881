@import "../../../../styles/mixins";

.shyft-pass-benefits-wrapper {
  display: flex;
  padding: 32px 0;
  background: #e0f2fe;
  border-radius: 24px;
  @include desktop {
    padding: 0 16px 0 56px;
    align-items: flex-end;
  }
  .shyft-pass-benefits {
    @include flex-center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    @include desktop {
      padding: 56px 0;
      gap: 24px;
    }
    .heading {
      font-family: var(--dm-sans);
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.02em;
      color: #293056;
      padding: 0 24px;
      @include desktop {
        font-size: 24px;
        line-height: 31px;
        text-align: left;
        padding: 0;
        align-self: flex-start;
      }
    }
    .content {
      @include flex-center;
      flex-direction: column;
      gap: 24px;
      @include desktop {
        width: 100%;
      }
      .detail {
        padding: 0 24px;
        @include desktop {
          padding: 0;
          max-width: 550px;
          align-self: flex-start;
        }
        .title {
          font-family: var(--dm-sans);
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--purple-800);
          @include desktop {
            font-size: 20px;
            line-height: 30px;
          }
        }
        .description {
          font-family: var(--dm-sans);
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--text-black);
          @include desktop {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
.benefits-image-container {
  width: 100%;
}
.benefits-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 68.57%;
  @include desktop {
    min-width: 536px;
    padding-top: 77.23%;
  }
}

.cta-section {
  width: 100%;
  button {
    width: 100%;
    filter: drop-shadow(2px 2px 30px rgba(130, 90, 219, 0.5));
    background: linear-gradient(45deg, #6941c6 0%, #7f56d9 100%);
    border-radius: 48px;
    padding: 20px 22px;

    p {
      font-family: var(--dm-sans);
      font-weight: 500;
      font-size: 15px;
      line-height: 19.53px;
      color: #ffffff;
    }

    @media screen and (max-width: 400px) {
      p {
        font-family: var(--dm-sans);
        font-weight: 500;
        font-size: 12px;
        line-height: 15.53px;
        color: #ffffff;
      }
    }
  }
  &.mobile-cta {
    @include flex-center;
    @include desktop {
      justify-content: flex-start;
    }
    button {
      padding: 20px 32px;
      width: fit-content;
      @include desktop {
        padding: 20px 32px;
      }
      p {
        font-size: 12px;
        line-height: 15.53px;
        @include desktop {
          font-size: 16px;
          line-height: 21px;
          width: max-content;
        }
      }
    }
  }
  @media screen and (min-width: 651px) {
    min-width: 620px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      border-radius: 48px;
      padding: 19.5px 32px;
      max-width: 422px;
      p {
        font-weight: 500;
      }
    }
  }
}
