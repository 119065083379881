@import "@/styles/mixins";

.textInput {
  padding: 15px 20px;
  height: 48px;
  border-radius: 48px;
  font-family: var(--man);
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #282828 !important;
  background: var(--white);
  border: none;
  width: 100%;
  @include desktop {
    padding: 19px 24px;
    height: 56px;
    border-radius: 56px;
  }
  &:focus {
    outline: 1px solid #282828;
  }
  @include inputStyles();
}
