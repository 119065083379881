.accordionWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  cursor: pointer;
  width: 100%;
}

.accordion {
  position: relative;
  overflow: hidden;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.iconWrapper {
  height: 40px;
  width: 40px;
  padding: 0;
}
.icon {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f8f9fc;
  padding: 16px;
  &.close {
    background-color: #282828;
  }
}
.title {
  font-family: var(--dm-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #282828;
  margin: 0;
}
.content {
  overflow: hidden;
  transition: all 3s ease-in-out;
  font-family: var(--dm-sans);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #282828;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
}
.accordion.open .content {
  -webkit-line-clamp: unset;
}

@media screen and (min-width: 651px) {
  .accordion {
    max-width: 932px;
  }
  .icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f8f9fc;
    padding: 16px;
  }
  .title {
    font-size: 18px;
    line-height: 23px;
  }
  .content {
    overflow: hidden;
    transition: all 3s ease-in-out;
    font-family: var(--dm-sans);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #282828;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  .accordion.open .content {
    -webkit-line-clamp: unset;
  }
}
