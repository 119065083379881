@import "@/styles/mixins";

.countryCode {
  position: absolute !important;
  height: 48px;
  width: 70px;
  @include desktop {
    height: 56px;
    width: 80px;
  }
  :global(.countryCode__control) {
    border: none;
    background-color: transparent;
    padding: 9px 0 9px 16px;
    height: 48px;
    @include desktop {
      padding: 12px 0 12px 16px;
      height: 56px;
    }
  }
  :global(.countryCode__menu) {
    background-color: var(--white);
    z-index: 51;
    margin: 4px 0 0 8px;
    border-radius: 8px;
    :global(.countryCode__menu-list) {
      max-height: 160px;
      :global(.countryCode__option) {
        padding: 8px 16px;
        font-family: var(--man);
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: #282828;
      }
    }
  }
  :global(.countryCode__value-container) {
    padding: 0;
    justify-content: center;
  }
  :global(.countryCode__indicator) {
    padding: 0;
    svg {
      height: 16px;
      width: 16px;
      fill: #282828;
    }
  }
  :global(.countryCode__indicator-separator) {
    display: none;
  }
  :global(.countryCode__single-value) {
    font-family: var(--man);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #282828;
  }
}
