@mixin hoverLight {
  background: rgba(0, 0, 0, 0.05) !important;
  border-radius: 37px !important;
  .navigationMenuLink {
    text-decoration: none;
    color: #6e55d3;
  }
  .caretDown {
    transform: rotate(-180deg);
    fill: #6e55d3;
    stroke: #6e55d3;
  }
}

@mixin hoverDark {
  background: rgba(0, 0, 0, 0.1) !important;
  .navigationMenuLink {
    color: #fff;
  }
  .caretDown {
    transform: rotate(-180deg);
    fill: #fff;
    stroke: #fff;
  }
}

.navigationMenuRoot {
  position: relative;
  display: flex;
  z-index: 1;
  &.navMenu {
    margin-right: 24px;
  }
  .navigationMenuList {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    gap: 8px;
    .navigationMenuItem {
      align-self: center;
      position: relative;
    }
    .navigationMenuLink {
      font-family: var(--man-700);
      font-size: 13px;
      line-height: 16px;
      color: #1D2939;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .navigationMenuTrigger {
      outline: none;
      user-select: none;
      background: transparent;
      border: none;
      .navigationMenuLink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding: 16px 8px 16px 8px;
        // &.noChild {
        //   padding: 16px;
        // }
      }
      .caretDown {
        position: relative;
        top: 1px;
        transition: transform 250ms ease;
        height: 12px;
        width: 12px;
        fill: #293056;
        stroke: #293056;
        flex-shrink: 0;
      }
      &[data-state="open"] {
        @include hoverLight;
      }
      &:hover {
        @include hoverLight;
      }
    }

    .profile {
      .navigationMenuTrigger {
        padding: 0;
      }
      .navigationMenuContent {
        left: unset;
        right: -10px;
      }
    }

    .navigationMenuContent {
      width: max-content;
      position: absolute;
      transform: translateY(100%);
      left: 0;
      bottom: -8px;
      animation-duration: 250ms;
      animation-timing-function: ease;
      &[data-motion="from-start"] {
        animation-name: enterFromBottom;
      }
      &[data-motion="to-start"] {
        animation-name: exitToBottom;
      }
    }

    .navigationMenuIndicator {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 10px;
      top: 100%;
      overflow: hidden;
      z-index: 1;
      transition: width, transform 250ms ease;
      &[data-state="visible"] {
        animation: fadeIn 200ms ease;
      }
      &[data-state="hidden"] {
        animation: fadeOut 200ms ease;
      }
      .arrow {
        position: relative;
        top: 70%;
        background-color: white;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        border-top-left-radius: 2px;
      }
    }
  }
}

.navigationMenuRoot.darkMode {
  .navigationMenuList {
    .navigationMenuLink {
      color: #fff;
      font-family: var(--man-500);
    }
  }
  .navigationMenuTrigger {
    .caretDown {
      * {
        fill: #fff;
        stroke: #fff;
      }
    }
    &[data-state="open"] {
      @include hoverDark;
    }
    &:hover {
      @include hoverDark;
    }
  }
}

.subNavigationMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0px 12px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 100%;
  min-width: 260px;
  z-index: 1000;
  list-style: none;
  .subNavigationMenuItem {
    width: 100%;
    .subNavigationMenuLink {
      display: block;
      width: 100%;
      padding: 16px;
      font-family: var(--man-700);
      font-size: 14px;
      line-height: 16px;
      color: #293056;
      cursor: pointer;
      &:hover {
        text-decoration: none;
        background-color: #ebe9fe;
        color: #6e55d3;
      }
      &.isSpecialLinkWrapper {
        &:hover {
          background-color: unset;
          color: unset;
        }
      }
      .specialLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        background-color: #fef0c7;
        border-radius: 12px;
        margin: 0;
        > p {
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: var(--man-700);
          font-size: 14px;
          line-height: 16px;
          color: #f79009;
          margin: 0;
        }
      }
    }
  }
}

@keyframes enterFromBottom {
  from {
    opacity: 0;
    transform: translateY(calc(100% + 50px));
  }
  to {
    opacity: 1;
    transform: translateY(100%);
  }
}

@keyframes exitToBottom {
  from {
    opacity: 1;
    transform: translateY(100%);
  }
  to {
    opacity: 0;
    transform: translateY(calc(100% + 50px));
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
