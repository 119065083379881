@import "@/styles/mixins";

.radioLabel {
  position: relative;
  margin: 0;
  padding-left: calc(var(--radio-button-size, 20px) + 10px);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #344054;
  font-family: var(--man-600);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0em;
  .radioInput {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: var(--radio-button-size, 20px);
    width: var(--radio-button-size, 20px);
    border-radius: 50%;
    border: 1.5px solid #98a2b3;
    transition: all 0.3s;
    flex-shrink: 0;
  }

  input:checked ~ .checkmark {
    background: transparent;
    border: 1.5px solid var(--radio-button-color, #12b76a);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(var(--radio-button-size, 20px) / 2);
    height: calc(var(--radio-button-size, 20px) / 2);
    border-radius: 50%;
    background: var(--radio-button-color, #12b76a);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}
