* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  scroll-behavior: smooth;
}

body,
body * {
  font-family: $primary-font-family;
  color: $primary-grey-color;
  font-size: $primary-font-size;
}

@media screen and (max-width: $breakpoint-767-below) {
  body,
  body * {
    font-size: $primary-mobile-font-size;
  }
}

body > iframe[style^="position"] {
  pointer-events: none;
}

.account-deleted-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px;
  h1 {
    color: #282828;
    font-family: var(--dm-sans);
    font-size: 32px;
    font-weight: normal;
  }
}

#fbcapture {
  position: fixed;
  top: 10px;
  left: 10px;
}

.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}
.btn-link {
  padding: 0;
  color: $primary-purple-color;
  &:hover,
  &:active,
  &:focus {
    color: $primary-purple-color;
  }
}
.color-purple {
  color: $primary-purple-color;
}
.spinner-border-xs {
  width: 0.75rem;
  height: 0.75rem;
}
.btn-custom,
.btn-custom:hover,
.btn-custom:active,
.btn-custom:focus,
.btn-custom:not(:disabled):not(.disabled):active,
.btn-custom:disabled {
  padding: 14px 40px;
  border-radius: 40px;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  outline: none;
  box-shadow: none;
  &:disabled {
    cursor: default;
  }
  &.btn-blue {
    background-color: #d0c8ef;
    color: $primary-purple-color;
    border: none;
    box-shadow: none;
    &.btn-small {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &.btn-purple {
    background-color: $primary-purple-color;
    color: $primary-white-color;
    border: none;
    box-shadow: none;
    span {
      color: $primary-white-color;
      font-size: 1.2rem;
      &.price-before {
        text-decoration: line-through;
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}
.heading1 {
  color: $primary-purple-color;
  font-size: $heading-font-size;
  font-weight: 900;
  text-align: center;
}
.heading2 {
  color: $primary-purple-color;
  font-size: $heading2-font-size;
  font-weight: 700;
  text-align: center;
}

.heading3 {
  color: $primary-purple-color;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

.main-wrapper {
  //background-image: linear-gradient(#fefeff,#e2e2e2);
  .left-wrapper,
  .right-wrapper,
  .inner-wrapper {
    min-height: calc(100vh - 97px);
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-group,
  .form-non-group {
    input {
      font-size: $primary-font-size;
      background: none;
      padding: 0.375rem 0;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      box-shadow: none !important;
      border-bottom: 1px solid $input-border-color;
      color: $primary-grey-color;
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #495057 !important;
      }
      &:active,
      &:focus {
        border-bottom-color: $primary-purple-color;
      }
    }
  }
}
.invalid-feedback {
  padding-left: 20px;
  display: inline-block;
  img {
    height: 12px;
    width: 12px;
    margin-top: -3px;
    margin-right: 3px;
  }
}
.modal-open {
  padding-right: 0 !important;
}
@media screen and (max-width: $breakpoint-991-below) {
  .main-wrapper {
    .left-wrapper {
      min-height: 10vh;
      .graphic-wrapper {
        width: 114px;
        height: 114px;
        margin: 3px 0 10px;
      }
    }
    .right-wrapper {
      min-height: calc(100vh - 254px);
      display: block;
    }
    .heading1 {
      font-size: $heading-mobile-font-size;
    }
    .heading2 {
      font-size: $heading2-mobile-font-size;
    }
  }
  .btn-custom,
  .btn-custom:hover,
  .btn-custom:active,
  .btn-custom:focus,
  .btn-custom:not(:disabled):not(.disabled):active {
    border-radius: 40px;
    font-size: 16px;
    padding: 16px 35px;
    &.btn-blue {
      &.btn-small {
        padding-left: 17px;
        padding-right: 17px;
      }
    }
  }
}

._loading_overlay_wrapper {
  position: absolute !important;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
}

.overlay-loader-content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 880;
  font-size: 18px;
  text-align: center;

  .spinner-border {
    margin: 20px auto;
    color: rgba(255, 255, 255, 0.8);
  }
  .overlay-loader-text {
    color: rgba(255, 255, 255, 0.9);
  }
}
.cookie-consent-container {
  align-items: baseline;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  color: rgb(51, 51, 51);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: rgba(172, 171, 171, 0.3) 0px -1px 10px 0px;
  bottom: 0px;
}

.cookie-consent-container.privacy-policy {
  right: 0;
  left: auto;
  width: auto;
  background: #ffffff;
  color: #333333;
  padding: 15px;
  cursor: pointer;
}

.cookie-consent-text {
  flex: 1 0 300px;
  margin: 15px;
}

.cookie-consent-decline-btn {
  background: rgb(140, 123, 209) none repeat scroll 0% 0%;
  border: 0px none;
  border-radius: 0px;
  box-shadow: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px 15px 15px 0px;
  font-size: 13px;
}

.cookie-consent-accept-btn {
  background: rgb(97, 162, 41) none repeat scroll 0% 0%;
  border: 0px none;
  border-radius: 0px;
  box-shadow: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
  font-size: 13px;
}

.modal-custom .modal-content {
  border-radius: 8px;
  padding-bottom: 10px;
}

.btn-custom.btn-light,
.btn-custom.btn-light:active,
.btn-custom.btn-light:focus,
.btn-custom.btn-light:not(:disabled):not(.disabled):active {
  margin: 0 auto;
  background-color: #f7f6fd;
  color: $primary-purple-color;
  border: 0;
}

.btn-custom.btn-white {
  width: auto;
  margin: 0 auto;
  background-color: $primary-white-color;
  color: $primary-purple-color;
  border: 0;
}

.btn-custom.btn-disabled-light:disabled {
  background-color: #f7f6fd;
  color: $primary-purple-color;
}

.background-light {
  background-color: #fff;
}

.text-error {
  color: #fc3903;
}

#zmmtg-root {
  display: none;
}

.container {
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.seo-heading-1 {
  display: none;
  visibility: hidden;
}

.desktopContainer {
  @include mobile {
    display: none;
  }
}
.mobileContainer {
  @include desktop {
    display: none;
  }
}
.custom_fc_frame{
  @include mobile{
    z-index: 1049 !important;
    bottom: 75px !important;
  }
}

.ndfHFb-c4YZDc-Wrql6b{
  display: none !important;
}
