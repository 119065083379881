.shyft-pass-app-view-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  // padding-left: 20px;
  // padding-right: 20px;
  .hero-section {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .name-title {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.1em;
      color: #282828;
      text-transform: uppercase;
    }
    .title {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
      letter-spacing: -0.03em;
      background: linear-gradient(
        94.35deg,
        #406bee -2.93%,
        #406bee 40.93%,
        #f33f5f 130.94%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      padding: 0px 8px;
    }
  }
  .app-view-faq-section {
    margin: 56px 0px 60px 0px;
  }
}
