@import "@/styles/mixins";

.courseHours {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;

    .totalHours {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      font-family: var(--man-700);
      color: #101828;
      @include desktop{
        font-size: 20px;
        line-height: 24px;
      }
    }

    .fieldWrapper {
      padding: 0px 4px 8px 4px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-bottom: 1px solid rgba(16, 19, 35, 0.2);
      gap: 4px;
      :global(.textInput) {
        font-size: 30px;
        line-height: 40px;
        font-weight: 600;
        font-family: var(--man-600);
        color: #1D2939;
        border: none;
        outline: none;
        padding: 0 !important;
        text-align: center;
        max-width: 60px;
        @include desktop{
            max-width: 100px;
            font-size: 36px;
            line-height: 48px;
        }

        &::placeholder {
          font-size: 30px;
          line-height: 40px;
          align-items: center;
          font-weight: 600;
          font-family: var(--man-600);
          color: #1D2939;
          text-align: center;
          opacity: 0.3;
          @include desktop{
            font-size: 36px;
          line-height: 48px;
          }
        }
      }

      label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        color: #667085;
        font-family: var(--man-700);
        margin: 0;
        padding-bottom: 9px;
      }
    }
  }