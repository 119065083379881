@import "@/styles/mixins";

.selectInput {
  width: 100%;
  @include mobile {
    font-size: 16px;
  }
  :global(.selectInput.dark) {
    :global(.selectInput__control) {
      background-color: var(--gray-50);
    }
    :global(.selectInput__menu) {
      background-color: var(--gray-50);
    }
  }
}
