/* Footer section style */
.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-text);
}

.footer-container {
  padding: 60px 116px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;
  max-width: 1600px;
  p {
    margin: 0;
  }
}
.footer__bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__bottom-text {
  font-family: var(--dm-sans);
  max-width: 880px;
  margin: 0;
  //   font-family: "Mori Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  opacity: 0.3;

  @media screen and (max-width: 651px) {
    line-height: 16px;
  }
}

.footer__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  @media screen and (max-width: 651px) {
    align-items: center;
  }
}

.footer__top-first-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  .logo-container {
    position: relative;
    width: 193.2px;
    height: 56px;
    .brand-logo-web {
      object-fit: cover;
    }
  }
}

.footer__top-first-column p:nth-child(1) {
  font-size: 40px;
  line-height: 54px;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.02em;
  font-family: var(--dm-sans);
}

.footer__top-second-column {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footer__top-second-column a {
  text-decoration: none;
  text-align: center;
}

.footer__top-second-column a:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 3;
}
.footer__top-second-column a:nth-child(6) {
  grid-column-start: 3;
  grid-column-end: 4;
}

.footer__top-second-column a p {
  font-family: var(--dm-sans);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  align-items: flex-end;
  letter-spacing: 0.02em;

  color: #ffffff;

  opacity: 0.7;
}

.footer__top-third-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  width: 235px;
  flex-shrink: 0;
}
.footer__top-third-column p {
  font-family: var(--dm-sans);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-align: right;
  letter-spacing: 0.02em;

  color: #ffffff;

  opacity: 0.7;
}

.footer__top-third-column div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
}
.footer__top-third-column div a {
  text-decoration: none;
}
.app-logo-container {
  position: relative;
  width: 117px;
  height: 38px;

  @media screen and (max-width: 651px) {
    width: 148.37px;
    height: 48.19px;
  }
}
.footer__top-third-column div a img {
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
}

@media screen and (max-width: 651px) {
  .footer-container {
    padding: 56px 32px 40px 32px;
    gap: 2.4rem;
  }

  .footer__top {
    flex-direction: column;
    gap: 48px;
  }

  .footer__top-first-column {
    gap: 1.2rem;

    .logo-container {
      width: 164px;
      height: 47.54px;
    }
  }

  .footer__top-first-column p:nth-child(1) {
    font-size: 2.4rem;
    line-height: 2.9rem;
  }
  .footer__top-first-column p:nth-child(2) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .footer__top-second-column {
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  .footer__top-second-column a {
    text-align: center;

    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 5;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 4;
    }
    &:nth-child(5) {
      order: 2;
    }
    &:nth-child(6) {
      order: 6;
    }
  }

  .footer__top-second-column a:nth-child(5) {
    grid-column-start: unset;
    grid-column-end: unset;
  }
  .footer__top-second-column a:nth-child(6) {
    grid-column-start: unset;
    grid-column-end: unset;
  }

  .footer__top-second-column a p {
    white-space: nowrap;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: 0.02em;
    color: #ffffff;
    opacity: 1;
  }

  .footer__top-third-column {
    margin-top: 0rem;
    align-items: center;
    width: 100%;
  }
  .footer__top-third-column p {
    line-height: 18px;
    text-align: center;
  }

  .footer__top-third-column div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1.2rem;
  }
  .footer__top-third-column div a {
    text-decoration: none;
  }
  .footer__top-third-column div a img {
    width: 14.83rem;
    height: 4.81rem;
  }
}
