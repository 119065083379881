@import "@/styles/mixins";

.submitButtonWrapper {
  width: 100%;
  .packDetail {
    @include flex-center;
    border-radius: 10px 10px 0px 0px;
    background: var(--warning-100);
    padding: 10px 10px 20px;
    color: #282828;
    font-family: var(--man);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.28px;
    text-align: center;
    margin-bottom: -10px;
  }
  .submitButton {
    width: 100%;
    padding: 19px 36px;
  }
}
