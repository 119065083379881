.accordionRoot {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  .accordionItem {
    overflow: hidden;
    margin-bottom: 16px;
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      margin-bottom: 56px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:focus-within {
      position: relative;
      z-index: 1;
      box-shadow: 0 0 0 2px var(--mauve12);
    }
    .accordionHeader {
      display: flex;
      margin: 0;
      .accordionTrigger {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding: 12px 20px;
        background: transparent;
        border: none;
        .accordionTriggerLink {
          font-family: var(--man-700);
          font-size: 18px;
          line-height: 25px;
          color: #293056;
          margin: 0;
        }
        &:hover {
          background-color: var(--mauve2);
        }
        &[data-state="open"] {
          padding: 12px 20px;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 37px;
          .accordionTriggerLink {
            color: #6e55d3;
          }
          > .accordionChevron {
            fill: #6e55d3;
            transform: rotate(180deg);
          }
        }
        .accordionChevron {
          color: var(--violet10);
          transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
          height: 20px;
          width: 20px;
        }
      }
    }
    .accordionContent {
      overflow: hidden;
      font-size: 15px;
      color: var(--mauve11);
      background-color: var(--mauve2);
      &[data-state="open"] {
        animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
      &[data-state="closed"] {
        animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
      }
      .accordionContentText {
        padding: 8px 0 0;
      }
      .subNavigationMenu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0px 12px;
        background: #ffffff;
        border-radius: 16px;
        width: 100%;
        min-width: 260px;
        z-index: 1000;
        list-style: none;
        margin: 0;
        .subNavigationMenuItem {
          width: 100%;
          .subNavigationMenuLink {
            display: block;
            width: 100%;
            padding: 16px;
            font-family: var(--man-700);
            font-size: 16px;
            line-height: 16px;
            color: #293056;
            &:hover {
              text-decoration: none;
              background-color: #ebe9fe;
              color: #6e55d3;
            }
            &.isSpecialLinkWrapper {
              &:hover {
                background-color: unset;
                color: unset;
              }
            }
            .specialLink {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 16px;
              background-color: #fef0c7;
              border-radius: 12px;
              margin: 0;
              > p {
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: var(--man-700);
                font-size: 16px;
                line-height: 16px;
                color: #f79009;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
