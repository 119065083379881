.faq-section {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  scroll-behavior: smooth;

  .faq-title {
    font-family: var(--dm-sans);
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: var(--gray-800);
    margin: 0;
  }

  .faq-list-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .accordion-container {
      &:not(:last-child) {
        padding-bottom: 16px;
        border-bottom: 1px solid #ebebeb;
      }
    }
  }

  @media screen and (min-width: 651px) {
    gap: 48px;
    .faq-title {
      font-size: 40px;
      line-height: 52px;
    }
    .faq-list-container {
      width: 100%;
    }
  }
}
