@import "@/styles/mixins";

.yogaTrialSelectSlot {
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  .label {
    display: flex;
    align-items: center;
    gap: 2px;
    color: #101828;
    font-family: var(--man-700);
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @include desktop {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
    span {
      color: #cf3232;
      font-family: var(--man-400);
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
      letter-spacing: 0em;
      @include desktop {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .slotList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .slotChip {
      cursor: pointer;
      height: 32px;
      padding: 13px 16px;
      border-radius: 8px;
      @include flex-center;
      flex-direction: column;
      gap: 2px;
      border: 1px solid #e4e7ec;
      @include desktop {
        height: 48px;
      }
      .slot {
        @include flex-center;
        gap: 2px;
        color: #667085;
        font-family: var(--man-600);
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        letter-spacing: 0em;
        @include desktop {
          font-size: 16px;
          line-height: 22px;
        }
        .meridiem {
          font-family: var(--man-600);
          font-size: 10px;
          line-height: 14px;
          font-weight: 600;
          letter-spacing: 0em;
          @include desktop {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      &.active {
        border: 1.5px solid #101828;
        .slot {
          color: #101828;
          .meridiem {
            color: #101828;
          }
        }
      }
    }
  }
}
