.toggleInput {
  display: flex;
  input {
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked + label {
      .toggle {
        background: #34c759;
        &:after {
          left: calc(100% - 2px);
          transform: translateX(-100%);
        }
      }
    }
  }
  label {
    display: flex;
    gap: 16px;
    .content {
      color: #1d2939;
      font-family: var(--man);
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
      letter-spacing: 0em;
    }
    .toggle {
      flex-shrink: 0;
      cursor: pointer;
      width: 38px;
      height: 24px;
      background: #78788029;
      display: block;
      border-radius: 24px;
      position: relative;
      margin: 0;
      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 20px;
        transition: 0.3s;
      }
    }
  }
}
