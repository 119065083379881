@import "@/styles/mixins";

.callbackForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .header {
    @include flex-center;
    flex-direction: column;
    gap: 12px;
    .title {
      color: #101828;
      font-family: var(--man);
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
      text-align: center;
      @include desktop {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .description {
      color: #101828;
      font-family: var(--man);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      @include desktop {
        font-size: 16px;
        line-height: 24px;
      }
      strong {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: 700;
      }
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    .joinCommunity {
      :global(.label) {
        margin-top: 24px;
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid #0000001a;
        gap: 24px;
      }
    }
    :global(.nameInput),
    :global(.emailInput),
    :global(.phoneInput),
    :global(.promoCodeInput) {
      margin: 8px 0;
      input {
        @include inputStyles();
        border-radius: 0;
        border-bottom: 1px solid #10132333;
        &:focus {
          outline: unset;
        }
        &:-webkit-autofill:focus {
          border-bottom: 1px solid #10132333 !important;
        }
      }
    }
    :global(.nameInput),
    :global(.emailInput) {
      input {
        padding: 19px 0;
      }
    }
    :global(.phoneInput) {
      :global(.countryCode) {
        color: #101323;
      }
      :global(.countryCode__control) {
        max-width: 62px;
        padding: 12px 0;
      }
      &::after {
        left: 75px;
      }
    }
    :global(.promoCodeInput) {
      input {
        padding: 19px 0 19px 44px;
      }
      button {
        padding: 0px;
        right: 0px;
      }
      :global(.discount) {
        left: 8px;
      }
      button {
        p {
          color: #101323;
          font-weight: 700;
        }
      }
    }
    :global(.error) {
      margin-top: 12px;
    }
    :global(.formError) {
      margin-top: 24px;
    }
    .infoText {
      margin-top: 16px;
      color: #000;
      font-family: var(--man);
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
    .submitButton {
      margin-top: 32px;
      width: 100%;
      height: 48px;
      background: #101828;
      border-radius: 12px;
      @include desktop {
        margin-top: 24px;
      }
      p {
        @include flex-center;
        font-family: var(--man);
        gap: 8px;
        color: #fff;
        text-align: center;
        font-family: var(--man);
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }
}
