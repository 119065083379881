.checkbox {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: flex;
  gap: 8px;
  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .label {
    color: var(--text-color, #282828);
    font-family: var(--man);
    font-size: 12px;
    line-height: 150%;
    font-weight: 400;
    letter-spacing: -0.132px;
  }
}

.input {
  display: none;
}

.input:checked + .checkbox {
  .icon {
    animation: wave 0.4s ease;
    rect {
      stroke: #039855;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    path {
      stroke: #039855;
    }
  }
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
