#shyft-pass-header-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #6e55d3;
    padding: 15px 20px;
    @media (min-width: 360px) {
      padding: 12px 15px;
    }
    p {
      font-weight: 500;
      line-height: 18.23px;
      color: #53389e;
      font-family: var(--dm-sans);
      font-size: 14px;
      @media (min-width: 360px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

.shyft-pass-header {
  background: linear-gradient(
      0deg,
      rgba(244, 248, 254, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #ffffff;
  mix-blend-mode: lighten;
}

.shyft-pass-header-menu-text {
  font-family: var(--dm-sans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #282828;
  margin: 0px;
  cursor: pointer;
  width: max-content;
}
