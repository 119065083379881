@import "@/styles/mixins";

.selectHealthConditions {
  width: 100%;
  :global(.dark) {
    :global(.healthConditions__control) {
      background-color: var(--gray-50);
    }
  }
}
