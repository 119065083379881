@import "@/styles/mixins";

.phoneInput {
  position: relative;
  width: 100%;
  :global(input.dark) {
    background-color: var(--gray-50);
  }
  :global(.textInput) {
    padding: 15px 20px 15px 84px;
    @include desktop {
      padding: 19px 24px 19px 95px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    left: 75px;
    top: 14px;
    height: 16px;
    width: 1.5px;
    background-color: #282828;
    opacity: 0.15;
    @include desktop {
      top: 18px;
      left: 85px;
      height: 20px;
    }
  }
  &:global(.fixedCountryCode) {
    :global(.countryCode) {
      @include flex-center;
      position: absolute !important;
      font-family: var(--man);
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: #282828;
      height: 48px;
      width: 40px;
      @include desktop {
        height: 56px;
        width: 50px;
      }
    }
    :global(.textInput) {
      padding: 15px 20px 15px 54px;
      @include desktop {
        padding: 19px 24px 19px 65px;
      }
    }
    &::after {
      left: 45px !important;
      @include desktop {
        left: 55px !important;
      }
    }
  }
}
