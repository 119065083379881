@import "@/styles/mixins";

.healthConditions {
  width: 100%;
  :global(.healthConditions__control) {
    height: 48px;
    border: none;
    box-shadow: none;
    background-color: var(--white);
    border-radius: 48px;
    padding: 15px 20px;
    @include desktop {
      height: 56px;
      padding: 19px 24px;
    }
    &:global(.healthConditions__control--menu-is-open) {
      outline: 1px solid #282828 !important;
    }
    :global(.healthConditions__indicator) {
      padding-right: 16px;
      svg {
        fill: #282828;
      }
    }
    :global(.healthConditions__indicator-separator) {
      display: none;
    }
  }
  :global(.healthConditions__menu) {
    padding: 19px 18px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.05);
    max-height: 150px;
    overflow-y: scroll;
    z-index: 10;
    @include desktop {
      max-height: unset;
      overflow-y: unset;
    }
  }
  :global(.healthConditions__placeholder) {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
  }
  :global(.healthConditions__single-value) {
    font-family: var(--man);
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
  }
  :global(.healthConditions__single-value) {
    color: #282828;
  }
  .healthConditionsDropdown {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    .option {
      padding: 8px 12px;
      svg * {
        fill: #eba368;
      }
    }
  }
  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    svg * {
      fill: #282828;
    }
    .optionText {
      color: #282828;
      font-family: var(--man);
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
    }
  }
}
