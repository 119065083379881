@import "@/styles/mixins";

.callbackModal {
  @include mobile {
    max-width: calc(100vw - 32px) !important;
  }
  @include desktop {
    max-width: 480px !important;
  }
  &.callbackFormWrapper {
    > div {
      padding: 64px 24px 32px;
    }
  }
}
