$breakpoint-992-above: 992px;
$breakpoint-991-below: 991px;
$breakpoint-767-below: 767px;
$breakpoint-1200-below: 1200px;

$primary-font-family: "Raleway-medium", sans-serif;
$primary-white-color: #ffffff;
$primary-grey-color: #5c5c6e;
$secondary-grey-color: #5c5c6e80;
$tertiary-grey-color: #93939e;
$primary-black-color: #000000;
$primary-purple-color: #8c7bd1;

$primary-grey-gradient-color: #fefeff;
$secondary-grey-gradient-color: #b3e2e2e2;
$input-border-color: #5c5c6e80;

$primary-font-size: 0.875rem;
$primary-mobile-font-size: 14px;
$heading-font-size: 40px;
$heading-mobile-font-size: 20px;
$heading2-font-size: 24px;
$heading2-mobile-font-size: 20px;
