#DataProcessingAgreementPage {
  margin: 24px auto;
  &.appPage {
    margin: 100px auto 24px;
  }
  @include mobile {
    .tableWrapper {
      max-width: 100vw;
      overflow: auto;
      @include hideScrollbar;
      table {
        margin: 0 !important;
      }
    }
    li {
      margin-left: 10px !important;
      padding-left: 10px !important;
      &::before {
        margin-left: -20px !important;
        min-width: 20px;
      }
      &.c31 {
        margin-left: 15px !important;
        &::before {
          margin-left: -20px !important;
          min-width: 25px;
        }
      }
    }
  }
  ul.lst-kix_djmu3im2shsx-2 {
    list-style-type: none;
  }
  .lst-kix_djmu3im2shsx-0 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_djmu3im2shsx-1 > li:before {
    content: "\0025cb   ";
  }
  ul.lst-kix_djmu3im2shsx-3 {
    list-style-type: none;
  }
  ul.lst-kix_djmu3im2shsx-4 {
    list-style-type: none;
  }
  ul.lst-kix_djmu3im2shsx-5 {
    list-style-type: none;
  }
  ul.lst-kix_djmu3im2shsx-0 {
    list-style-type: none;
  }
  ul.lst-kix_djmu3im2shsx-1 {
    list-style-type: none;
  }
  .lst-kix_djmu3im2shsx-4 > li:before {
    content: "\0025cb   ";
  }
  ul.lst-kix_djmu3im2shsx-6 {
    list-style-type: none;
  }
  .lst-kix_djmu3im2shsx-2 > li:before {
    content: "\0025a0   ";
  }
  .lst-kix_djmu3im2shsx-3 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_djmu3im2shsx-7 {
    list-style-type: none;
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ul.lst-kix_djmu3im2shsx-8 {
    list-style-type: none;
  }
  .lst-kix_djmu3im2shsx-8 > li:before {
    content: "\0025a0   ";
  }
  .lst-kix_djmu3im2shsx-5 > li:before {
    content: "\0025a0   ";
  }
  .lst-kix_djmu3im2shsx-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_djmu3im2shsx-7 > li:before {
    content: "\0025cb   ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c3 {
    border-right-style: solid;
    padding: 5pt 5pt 5pt 5pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 156pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c8 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left;
  }
  .c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: var(--man);
    font-style: normal;
    @include mobile {
      font-size: 20pt;
    }
  }
  .c6 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: var(--man);
    font-style: normal;
    @include mobile {
      font-size: 10pt;
    }
  }
  .c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: var(--man);
    font-style: normal;
    @include mobile {
      font-size: 16pt;
    }
  }
  .c13 {
    padding-top: 0pt;
    padding-bottom: 3pt;
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  .c7 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: var(--man);
    font-style: normal;
  }
  .c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left;
  }
  .c1 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: center;
  }
  .c10 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c11 {
    background-color: #ffffff;
  }
  .c14 {
    padding: 0;
    margin: 10pt 0 0 0;
  }
  .c15 {
    height: 11pt;
  }
  .c4 {
    height: 0pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: var(--man);
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: var(--man);
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: var(--man);
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    text-align: left;
  }
}
