.shyft-pass-drawer {
  background: linear-gradient(82.07deg, #0f0623 0%, #0e2d5c 100%);
  overflow: hidden;
  .cta-section {
    width: calc(100% - 76px);
    position: absolute;
    bottom: 58px;
    left: 50%;
    transform: translateX(-50%);
    button {
      width: 100%;
      background: transparent;
      border-radius: 48px;
      padding: 16px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 60px;
      p {
        font-family: var(--dm-sans);
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .drawer-close-button {
    padding: 13.53px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .menu-link-text {
    @media screen and (max-width: 380px) {
      font-size: 28px;
      line-height: 28px;
    }
    &.menu-link-text-first-item {
      opacity: 1;
    }
  }
}
.shyft-pass-drawer-logo-container {
  width: 164px;
  height: 47.54px;
  position: relative;
  object-fit: cover;
}
.shyft-page-mobile-drawer-pass-card {
  position: absolute;
  bottom: 88px;
  // transform: matrix(0.88, -0.47, 0.71, 0.71, 0, 0);
  right: -50%;
  // filter: drop-shadow(-9px 20px 50px rgba(0, 0, 0, 0.9));
  .card-section {
    padding-top: 75.3%;
    width: 150vw;
    height: unset;
    z-index: 0;
    position: relative;
    overflow: hidden;
    .card-logo-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .image-wrapper {
        position: relative;
        height: 80px;
        width: 155px;
        .card-logo-image {
          object-fit: cover;
        }
      }
    }
    .card-text {
      position: absolute;
      bottom: 12px;
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 700;
      font-size: 11.18px;
      line-height: 15px;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0px;
      text-shadow: 0.159827px 0.159827px 0.23974px rgba(0, 0, 0, 0.35);
    }
    .card-left-text {
      left: 22.78px;
    }
    .card-right-text {
      right: 22.78px;
    }
  }
}
