.callbackSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  .icon {
    height: 64px;
    width: 64px;
  }
  .title {
    color: #101828;
    text-align: center;
    font-family: var(--man-700);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  .description {
    color: #101828;
    text-align: center;
    font-family: var(--man);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
}
