.shyft-pass-content-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 56px;
  padding-top: 48px;
  padding-bottom: 56px;
  @media screen and (min-width: 651px) {
    gap: 80px;
    padding-top: 80px;
    padding-bottom: 104px;
  }
}

.all-slots-booked {
  padding: 17px 32px !important;
}
