@import "@/styles/mixins";

.promoCodeInputWrapper {
  position: relative;
  .discount {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    z-index: 2;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  .actionButton {
    background: transparent;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    p {
      color: #99f;
      font-family: var(--man);
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      height: unset;
      padding: 8px;
    }
    &:hover {
      background: transparent;
      p {
        color: #99f;
      }
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    z-index: 2;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}

.promoCodeInput {
  padding: 15px 20px 15px 52px;
  height: 48px;
  border-radius: 48px;
  font-family: var(--man);
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: #282828 !important;
  background: var(--white);
  border: none;
  width: 100%;
  @include desktop {
    padding: 19px 24px 19 56px;
    height: 56px;
    border-radius: 56px;
  }
  &:focus {
    outline: 1px solid #282828;
  }
  @include inputStyles();
}
