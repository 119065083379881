@import "@/styles/mixins";

.selectInput {
  @include mobile {
    font-size: 16px;
    * {
      font-size: 16px;
    }
  }
  :global(.selectInput__control) {
    padding: 15px 20px;
    height: 48px;
    border-radius: 48px;
    font-family: var(--man);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #282828;
    background: var(--white);
    border: none;
    width: 100%;
    @include desktop {
      padding: 19px 24px;
      height: 56px;
      border-radius: 56px;
    }
    &:focus {
      outline: 1px solid #282828;
    }
    &:global(.selectInput__control--menu-is-open) {
      border-radius: 16px 16px 0 0 !important;
      border: 1px solid #282828 !important;
      border-bottom: none !important;
    }
  }
  :global(.selectInput__placeholder) {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
  }
  :global(.selectInput__menu) {
    z-index: 50;
    border-radius: 0 0 16px 16px !important;
    border: 1px solid #282828 !important;
    border-top: none !important;
    :global(.selectInput__menu-list) {
      :global(.selectInput__option) {
        padding: 11px 24px;
        color: #282828;
        font-family: var(--man);
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
      }
    }
  }
  input {
    @include mobile {
      font-size: 16px !important;
    }
  }
}
