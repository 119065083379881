@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLWU6uq_pVWCgBLCs0yVne9VWQcMt4LNPAicvr8auQQHLa7Zo1nuZHHjENflJxJ8CTw);

#CookiePolicyPage {
  margin-top: 100px;
  @include mobile {
    .tableWrapper {
      max-width: 100vw;
      overflow: auto;
      @include hideScrollbar;
      table {
        margin: 0 !important;
      }
    }
    li {
      margin-left: 10px !important;
      padding-left: 10px !important;
      &::before {
        margin-left: -20px !important;
        min-width: 20px;
      }
      &.c31 {
        margin-left: 15px !important;
        &::before {
          margin-left: -20px !important;
          min-width: 25px;
        }
      }
    }
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  ol.lst-kix_dc228udvun1m-5.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-5 0;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  .lst-kix_9p8gv4yir6dr-0 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-0;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  .lst-kix_dc228udvun1m-8 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-8;
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  ol.lst-kix_dc228udvun1m-8.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-8 0;
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  .lst-kix_dc228udvun1m-8 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-8, lower-roman) ". ";
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  .lst-kix_dc228udvun1m-5 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-5, lower-roman) ". ";
  }
  ol.lst-kix_dc228udvun1m-2.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-2 0;
  }
  .lst-kix_dc228udvun1m-4 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-4, lower-latin) ". ";
  }
  .lst-kix_dc228udvun1m-6 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-6, decimal) ". ";
  }
  .lst-kix_dc228udvun1m-3 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-3, decimal) ". ";
  }
  .lst-kix_dc228udvun1m-7 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-7, lower-latin) ". ";
  }
  ol.lst-kix_9p8gv4yir6dr-1.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-1 0;
  }
  .lst-kix_9p8gv4yir6dr-2 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-2;
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-0.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-0 0;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  .lst-kix_dc228udvun1m-0 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-0;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-7.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-7 0;
  }
  ol.lst-kix_9p8gv4yir6dr-4.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-4 0;
  }
  .lst-kix_dc228udvun1m-0 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-0, lower-latin) ". ";
  }
  .lst-kix_dc228udvun1m-2 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-2, lower-roman) ". ";
  }
  .lst-kix_9p8gv4yir6dr-8 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-8;
  }
  .lst-kix_dc228udvun1m-1 > li:before {
    content: "" counter(lst-ctn-kix_dc228udvun1m-1, lower-latin) ". ";
  }
  .lst-kix_dc228udvun1m-1 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-1;
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_9p8gv4yir6dr-7 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-7;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_9p8gv4yir6dr-1 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-1;
  }
  ol.lst-kix_dc228udvun1m-1.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-1 0;
  }
  .lst-kix_dc228udvun1m-7 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-7;
  }
  li.li-bullet-2:before {
    margin-left: -36pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 36pt;
  }
  ol.lst-kix_9p8gv4yir6dr-3.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-3 0;
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  ol.lst-kix_9p8gv4yir6dr-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
  }
  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  ol.lst-kix_9p8gv4yir6dr-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-6 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
  }
  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
  }
  .lst-kix_9p8gv4yir6dr-5 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-5;
  }
  ol.lst-kix_9p8gv4yir6dr-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-2.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-2 0;
  }
  ol.lst-kix_9p8gv4yir6dr-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  .lst-kix_dc228udvun1m-4 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-4;
  }
  .lst-kix_9p8gv4yir6dr-4 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-4;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-3 {
    list-style-type: none;
  }
  ol.lst-kix_9p8gv4yir6dr-5.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-5 0;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-2 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-1 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  ol.lst-kix_dc228udvun1m-7 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-6 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-5 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-4 {
    list-style-type: none;
  }
  ol.lst-kix_dc228udvun1m-8 {
    list-style-type: none;
  }
  li.li-bullet-1:before {
    margin-left: -36pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 36pt;
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  ol.lst-kix_9p8gv4yir6dr-8.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-8 0;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  ol.lst-kix_dc228udvun1m-6.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-6 0;
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  .lst-kix_dc228udvun1m-3 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-3;
  }
  .lst-kix_dc228udvun1m-6 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-6;
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  ol.lst-kix_9p8gv4yir6dr-7.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-7 0;
  }
  .lst-kix_9p8gv4yir6dr-3 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-3;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  ol.lst-kix_9p8gv4yir6dr-0.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-0 0;
  }
  ol.lst-kix_dc228udvun1m-3.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-3 0;
  }
  .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
  }
  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) ". ";
  }
  .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) ". ";
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) ". ";
  }
  .lst-kix_list_1-4 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) ". ";
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_9p8gv4yir6dr-8 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-8, lower-roman) ". ";
  }
  .lst-kix_9p8gv4yir6dr-7 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-7, lower-latin) ". ";
  }
  .lst-kix_dc228udvun1m-5 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-5;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) "."
      counter(lst-ctn-kix_list_1-7, decimal) ". ";
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) ". ";
  }
  .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) ". ";
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_9p8gv4yir6dr-6 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-6, decimal) ". ";
  }
  .lst-kix_dc228udvun1m-2 > li {
    counter-increment: lst-ctn-kix_dc228udvun1m-2;
  }
  .lst-kix_9p8gv4yir6dr-3 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-3, decimal) ". ";
  }
  .lst-kix_9p8gv4yir6dr-6 > li {
    counter-increment: lst-ctn-kix_9p8gv4yir6dr-6;
  }
  ol.lst-kix_9p8gv4yir6dr-6.start {
    counter-reset: lst-ctn-kix_9p8gv4yir6dr-6 0;
  }
  .lst-kix_9p8gv4yir6dr-1 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-1, lower-latin) ". ";
  }
  .lst-kix_9p8gv4yir6dr-5 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-5, lower-roman) ". ";
  }
  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, lower-latin) ". ";
  }
  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_9p8gv4yir6dr-0 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-0, lower-latin) ". ";
  }
  .lst-kix_9p8gv4yir6dr-4 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-4, lower-latin) ". ";
  }
  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
      counter(lst-ctn-kix_list_1-1, decimal) "."
      counter(lst-ctn-kix_list_1-2, decimal) "."
      counter(lst-ctn-kix_list_1-3, decimal) "."
      counter(lst-ctn-kix_list_1-4, decimal) "."
      counter(lst-ctn-kix_list_1-5, decimal) "."
      counter(lst-ctn-kix_list_1-6, decimal) "."
      counter(lst-ctn-kix_list_1-7, decimal) "."
      counter(lst-ctn-kix_list_1-8, decimal) ". ";
  }
  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
  }
  ol.lst-kix_dc228udvun1m-4.start {
    counter-reset: lst-ctn-kix_dc228udvun1m-4 0;
  }
  ol.lst-kix_9p8gv4yir6dr-0 {
    list-style-type: none;
  }
  .lst-kix_9p8gv4yir6dr-2 > li:before {
    content: "" counter(lst-ctn-kix_9p8gv4yir6dr-2, lower-roman) ". ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c24 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 104.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c23 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 124.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c12 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 102.3pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c21 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 99.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c1 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 103.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c15 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 103.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c18 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 104.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c28 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 102.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c6 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 104.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c11 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 79.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c17 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 110.4pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }
  .c16 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: var(--manrope);
    font-style: normal;
  }
  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: var(--manrope);
    font-style: normal;
  }
  .c10 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: var(--manrope);
    font-style: normal;
  }
  .c2 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c34 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c38 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c39 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: justify;
  }
  .c32 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: var(--manrope);
  }
  .c29 {
    margin-left: 35.5pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c36 {
    -webkit-text-decoration-skip: none;
    color: #0563c1;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c27 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
  .c4 {
    font-size: 12pt;
    font-family: var(--manrope);
    font-weight: 400;
  }
  .c20 {
    font-size: 12pt;
    font-family: var(--manrope);
    font-weight: 700;
  }
  .c35 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c40 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
  }
  .c25 {
    background-color: #ffffff;
  }
  .c7 {
    margin-left: 36pt;
    padding-left: 0pt;
  }
  .c30 {
    color: inherit;
    text-decoration: inherit;
  }
  .c13 {
    margin-left: 36pt;
    height: 11pt;
  }
  .c3 {
    padding: 0;
    margin: 0;
  }
  .c22 {
    font-style: normal;
    color: #000000;
  }
  .c31 {
    margin-left: 53.5pt;
    padding-left: 18pt;
  }
  .c9 {
    margin-left: 90pt;
    padding-left: 0pt;
  }
  .c8 {
    height: 0pt;
  }
  .c33 {
    margin-left: 36pt;
  }
  .c14 {
    height: 11pt;
  }
  .c19 {
    font-style: italic;
  }
  .c26 {
    height: 15pt;
  }
  .c37 {
    margin-left: 35.5pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: var(--manrope);
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: var(--manrope);
  }
  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: var(--manrope);
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
