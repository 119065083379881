@import "@/styles/mixins";

.packageCards{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 16px;
    .heading{
        font-size: 16px;
        line-height: 24px;
        font-family: var(--man-700);
        color: #131313;
    }
    .cardList{
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        @include mobile{
            flex-direction: column;
        }
        .card{
            background: #F9FAFB;
            box-shadow: 0px 0px 1px 0px #0000001A;
            border-radius: 12px;
            padding: 20px;
            display: flex;
            gap: 12px;
            cursor: pointer;
            @include mobile{
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
            @include desktop{
                flex-direction: column;
                padding: 20px;
            }
            .priceSection{
                display: flex;
                align-items: center;
                gap: 8px;
                @include mobile{
                    flex-direction: row-reverse;
                }
                .price{
                    background: #12B76A;
                    padding: 5px 10px;
                    border-radius: 16px;
                    // font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.2px;
                    font-family: var(--man-600);
                    color: #fff;
                    padding: 6px 12px;
                    font-size: 16px;
                    @include desktop{
                    }
                }
                .strikeout{
                    font-size: 13px;
                    line-height: 18px;
                    font-family: var(--man-500);
                    color: #282828;
                    position: relative;
                    &::after {
                        position: absolute;
                        content: "";
                        left: 0px;
                        right: 0px;
                        top: 50%;
                        border-top: 1px solid #f04438;
                        transform: translateY(-50%) rotate(-8deg);
                      }
                }
            }
            .duration{
                font-size: 18px;
                line-height: 24px;
                font-family: var(--man-700);
                color: #1D2939;
            }
        }
        .active{
            background: linear-gradient(45deg, #0E3FA9 0%, #032C66 100%);
            border: 4px solid #C7D7FE;
            .priceSection{
                .strikeout{
                    color: #fff;
                }
            }
            .duration{
                color: #fff;
            }
        }
    }
}