@import "../../../styles/mixins";

.shyft-pass-app-view-schedule-section {
  margin-top: 59px;
  .schedule-section {
    margin: 24px 20px 0;
  }
  .top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 47px;
    gap: 4px;
    .title {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 39px;
      color: #1d2939;
      margin: 0px;
    }
    .description {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #1d2939;
      margin: 0px;
    }
  }
  .dateCarousel {
    padding: 29px 74px 29px 20px;
    .slick-prev,
    .slick-next {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
      color: unset;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      &::before {
        content: none;
      }
    }
    .slick-prev {
      background: #f8f9fc;
      left: -100px;
    }
    .slick-next {
      background: #282828;
      right: -55px;
    }
    .slick-list {
      padding: 0 4px;
      .slick-track {
        .slick-slide {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .dateChip {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      border: 1px solid var(--purple-text);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 4px 8px rgba(0, 0, 0, 0.06);
      border-radius: 50px;
      background: var(--bluegray-50);
      span {
        font-family: var(--dm-sans);
        font-weight: 500;
        font-size: 12px;
        line-height: 19.52px;
        color: var(--bluegray-400);
      }
    }
    .slick-active {
      .dateChip {
        background: #ffffff;
        span {
          color: var(--primary-800);
        }
      }
    }
  }
}

.session-card-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  padding: 40px 20px 40px 20px;
  background-color: #eff8ff;
  @include desktop {
    border-radius: 20px;
  }
  .sessionCard {
    display: flex;
    gap: 16px;
    overflow: hidden;
    max-width: 870px;
    .thumbnail {
      position: relative;
      min-width: 125px;
      max-width: 125px;
      border-radius: 12px;
      overflow: hidden;

      img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .shyftLogo {
        position: absolute;
        @include desktop {
          height: 12px;
          width: 22px;
          top: 8px;
          left: 8px;
        }
        height: 12px;
        width: 23px;
        top: 7px;
        left: 7px;

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .sessionData {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .dateTime {
        display: flex;
        gap: 2px 14.5px;
        flex-wrap: wrap;
        .sessionTime,
        .separator,
        .sessionDate {
          font-family: var(--dm-sans);
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: var(--purple-text);
        }
        .separator {
          font-size: 12px;
          opacity: 0.7;
        }
      }
      .title {
        font-family: var(--dm-sans);
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: var(--gray-700);
      }
      .description {
        display: none;
        font-family: var(--dm-sans);
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: var(--text-black);
        margin-bottom: 16px;
      }
      .location {
        font-family: var(--dm-sans);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: var(--gray-700);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .detail-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 18px 24px;
    gap: 8px;
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    .detail-top-section {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      .workout-session-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 18px;
        .level,
        .parking,
        .attire {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .title {
            font-family: var(--dm-sans);
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #344054;
            margin: 0px;
          }
          .description {
            font-family: var(--dm-sans);
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 162.7%;
            letter-spacing: 0.01em;
            color: #282828;

            &.success-color {
              color: #039855;
            }
            &.danger-color {
              color: #cf3232;
            }
          }
        }
      }
      .workout-session-detail {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .class-detail {
          font-family: var(--dm-sans);
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 162.7%;
          letter-spacing: 0.01em;
          color: #282828;
          strong {
            font-family: var(--dm-sans);
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #344054;
            margin-bottom: 8px;
          }
          ul,
          ol {
            margin-left: 20px;
          }
        }
        .class-flow {
          .title {
            font-family: var(--dm-sans);
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #344054;
          }
          .list {
            padding-left: 15px;
            li {
              font-family: var(--dm-sans);
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 162.7%;
              letter-spacing: 0.01em;
              color: #282828;
            }
          }
        }
      }
    }
    .view-class-detail-cta {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      width: 100%;
      .cta {
        font-family: var(--dm-sans);
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #282828;
        margin: 0;
        cursor: pointer;
      }
      .icon {
        transform: rotate(-90deg);
        transition: all 0.3ms ease-in-out;
        &.open {
          transform: rotate(90deg);
        }
      }
    }
  }
  .slot-book-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    button {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 8px 8px 16px;
      gap: 4px;

      height: 60px;
      background: #282828;
      border-radius: 100px;

      @include mobile-lg {
        padding: 8px 24px 8px 32px;
      }
      p {
        font-family: var(--dm-sans);
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        .icon {
          margin-left: 8px;
        }
      }
      &.booking-cancel-button {
        background: transparent;
        p {
          color: #cf3232;
        }
      }
      &.all-slots-booked {
        border-color: #eaecf5 !important;
        background-color: #eaecf5 !important;
        p {
          color: #9ea5d1 !important;
        }
      }
    }
  }
  .slot-cancel-modal {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .modal-top-section {
      .title {
        font-family: var(--dm-sans);
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #344054;
        color: red !important;
      }
      .description {
        font-family: var(--dm-sans);
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 162.7%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #282828;
      }
    }
  }
}

.slot-cancel-modal {
  display: flex;
  flex-direction: column;
  gap: 40px;
  .modal-top-section {
    .title {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #344054;
      margin: 0;
    }
    .description {
      font-family: var(--dm-sans);
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 162.7%;
      text-align: center;
      letter-spacing: 0.01em;
      color: #282828;
      margin: 0;
    }
  }
  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px 8px 32px;
    gap: 8px;
    height: 64px;
    background: linear-gradient(45deg, #6941c6 0%, #7f56d9 100%);
    box-shadow: 2px 2px 30px rgba(130, 90, 219, 0.32);
    border-radius: 100px;
  }
}

.slot-book-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  .description {
    font-family: var(--dm-sans);

    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.01em;

    /* Gray / 700 */

    color: #344054;
    margin: 0;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px 8px 32px;
    gap: 8px;
    width: 100%;
    height: 64px;

    /* Gradient / Primary /  700 -> 600 (45deg) */

    background: linear-gradient(45deg, #6941c6 0%, #7f56d9 100%);
    box-shadow: 2px 2px 30px rgba(130, 90, 219, 0.32);
    border-radius: 100px;
  }
}

.session-horizontal-rule {
  width: 100%;
  margin: 32px 0;
  border-top: 1px solid rgba(41, 48, 86, 0.1);
}

.wait-list-wrapper {
  width: 100%;
  @include flex-center;
  flex-direction: column;
  gap: 12px;
}
