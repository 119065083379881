@import "@/styles/mixins";

.tncWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C7D7FE;
    z-index: 0;
    padding: 12px 0px 12px 0px !important;
    border-radius: 0px 0px 24px 24px;
    gap: 4px;
    @include mobile {
        padding: 12px !important;
        align-items: flex-start;
        border-radius: 0px 0px 12px 12px;
    }
    .tncContent{
        display: flex;
        align-items: center;
        color:#293056;
        font-family: var(--man-600);
        @include mobile{
            flex-direction: column;
            align-items: flex-start;
        }
        .text{
            strong{
                font-family: var(--man-700);
                @include mobile{
                    font-size: 10px;
                }
            }
        }
        .link{
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
  }