.mobileDrawer {
  background: #f3f3ef;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }
  .shyftPassButton {
    background: #d1e9ff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    border-radius: 0;
    padding: 21px 24px;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-family: var(--man-700);
        font-size: 16px;
        line-height: 22px;
        color: #1570ef;
        margin: 0;
      }
    }
    &:hover {
      background: #d1e9ff;
    }
  }
  .joinCommunityButton {
    background: #d3f0ae;
    width: 100%;
    border-radius: 0;
    padding: 21px 24px;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-family: var(--man-700);
        font-size: 16px;
        line-height: 22px;
        color: #487b07;
        margin: 0;
      }
    }
    &:hover {
      background: #d3f0ae;
    }
  }
  .appDownload {
    display: flex;
    flex-direction: column;
    padding: 40px 24px 48px;
    background: #6e55d3;
    .title {
      font-family: var(--man-700);
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 4px;
    }
    .description {
      font-family: var(--man-500);
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.4px;
      color: #e4e7ec;
      margin-bottom: 24px;
    }
    .icons {
      display: flex;
      gap: 10px;
    }
  }
}
