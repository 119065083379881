.tncModal{
    background-color: #fff !important;
    > div {
        padding: 64px 24px 32px 24px;
    }
    .title{
        color: #444CE7;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px;
    }
    .description{
        color: #293056;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }
}