@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin min-max-width($lb, $ub) {
  @media screen and (min-width: $lb) and (max-width: $ub) {
    @content;
  }
}

@mixin min-width($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin max-width($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin mobile-lg {
  @media screen and (min-width: 410px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}
@mixin mobile-sm {
  @media screen and (max-width: 365px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: var(--scroll-bar-width) !important;
    margin-right: var(--scroll-bar-width) !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: var(--scroll-bar-thumb-width) solid transparent !important;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: var(--scroll-bar-thumb-color);
    transition: all 0.2s ease-in-out;
  }
  &::-webkit-scrollbar:horizontal {
    width: var(--scroll-bar-width);
  }

  &::-webkit-scrollbar-track:horizontal {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border: var(--scroll-bar-thumb-width) solid transparent !important;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: var(--scroll-bar-thumb-color);
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scroll-bar-thumb-color) !important;
    }

    &::-webkit-scrollbar-track:horizontal {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: var(--scroll-bar-thumb-color);
    }
  }

  // Only works for firefox v64 and above
  scrollbar-color: var(--scroll-bar-thumb-color);
  scrollbar-width: thin;

  &:hover {
    scrollbar-color: var(--scroll-bar-thumb-color);
  }
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@mixin inputStyles($autofillColor: #fff) {
  &:-webkit-autofill {
    background-color: $autofillColor !important;
    -webkit-box-shadow: 0 0 0 50px $autofillColor inset;
  }

  &:-webkit-autofill:focus {
    background-color: $autofillColor !important;
    -webkit-box-shadow: 0 0 0 50px $autofillColor inset;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    font-family: var(--dm-sans);
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}
