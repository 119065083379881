@import "@/styles/mixins";

.error {
  display: flex;
  gap: 4px;
  margin-top: 6px;
  margin-left: 4px;
  .icon {
    height: 14px;
    width: 14px;
    fill: #cf3232;
    @include desktop {
      height: 18px;
      width: 18px;
    }
  }
  .errorMessage {
    color: #cf3232;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    @include desktop {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
